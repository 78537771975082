h1{
    font-weight: bold;
    padding: 0 0 35px;
}
.status-text{
    font-size: 24px;
    padding-bottom: 16px;
}
.card-title{
    font-size: 20px;
    padding: 15px 0 0;
    font-weight: bold;
}
.policy-table{
    // margin-bottom: 260px;
    thead{
        background-color: #F8F9F9;
    }
    tbody{
        tr{
            border-bottom: 1px solid #e3e3e3;
            height: 60px;
            line-height: 60px;
        }
    }
    td{
        text-align: center;
        color: #17324D;
    }
    td:nth-last-child(3){
        span{
            background-color: #E5E5E5;
            color: #17324D;
            width: 98px;
            height: 20px;
            border-radius: 5px;
            font-size: 12px;
            font-weight: bold;
            display: inline-block;
            line-height: 20px;
            letter-spacing: 0.5px;
        }
    }
    .btn {
        padding: 0 !important;
        width: 30px;
    }
    @media screen and (max-width: 999px) {
        /* Versione mobile */
        .btn {
          width: 80px;
        }
      
        th,
        td {
          font-size: 10px;
        }
      }

      @media screen and (min-width: 1000px) {
        th {
            font-size: 10px;
        }
        td {
            font-size: 10px;
        }
      }

      @media screen and (min-width: 1600px) {
        th {
            font-size: 12px;
        }
        td {
            font-size: 12px;
        }
      }


    th{
        text-align: center;
        /*font-size: 1.25em !important;*/
        
        border-bottom: 0;
    }
}
.dropdown-menu.show {
    width: 240px;
}
.dots-btn, .dots-btn:focus, a:focus{
    background: none;
    border:0 !important;
    box-shadow: none !important;
    outline: 0 !important;
}
.red-text{
    font-size: 28px;
    color: #FC4850;
}
.chip{
    margin-bottom: 20px;
}
.ipi-title{
    .chip{
        margin-top: 20px;
        color: #5C6F82;
        height: 36px;
        line-height: 36px;
    }
}
.warning-message{
    h4{
        font-size: 24px;
        margin: 20px 0 40px;
        color: darkred;
    }
}

.label-style{
    label{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}
.data-category{
    border: 1px solid #d8d8d8;
    background: #ebebeb;
    padding: 0 5px;
    margin-left: 5px;
}
.showfile:hover, .showfile:active, .showfile:focus{
    background-color: #06c;
}
.callout{
    padding: 2.5rem 0 2.5rem 1.8rem;
}
.dropDownButton{
    max-width: 30px;
    height: auto;
}

