body{
    --active-bg: rgba(122,119,255,0.1);
    --primary-bg: rgba(122,119,255,0.1);
    --primary-1: rgba(122,119,255,0.9);
    --primary-2: rgba(122,119,255,0.8);
    --primary-3: rgba(122,119,255,0.7);
    --primary-4: rgba(122,119,255,0.6);
    --primary-5: rgba(122,119,255,0.5);
    --primary-6: rgba(122,119,255,0.4);
    --primary-7: rgba(122,119,255,0.3);
    --primary-8: rgba(122,119,255,0.2);
    --primary-9: rgba(122,119,255,0.1);
    --primary-10: rgba(122,119,255,0);
    --light-5: rgba(243,243,251,0.5);
    --remove-btn-bg: rgba(122,119,255,0.1);
    --remove-btn-hover-bg: rgba(122,119,255,0.2);
    --primary-color: rgba(122,119,255,1);
    --success-bg-color: rgba(65,181,111,0.1);
    --success-color: rgba(65,181,111,0.9);
    --warning-bg-color: rgba(251,188,5,0.1);
    --warning-color: rgba(251,188,5,0.9);
    --pink-8: rgba(255,100,146,0.2);
    --pink-9: rgba(255,100,146,0.1);
    --heading-color: rgba(undefined,0.65);
    --link-color: rgba(122,119,255,1);
    --info-color: rgba(122,119,255,1);
    --layout-body-background: #f6f6f8;
    --layout-header-background: #eeeef5;
    --menu-highlight-color: rgba(20,20,70,1);
    --item-hover-bg: rgba(243,243,251,1);
    --layout-header-height: 48px;
    --font-family: Inter,-apple-system,BlinkMacSystemFont,"Segoe UI","Avenir Next",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
    --menu-item-font-size: 15px;
    --btn-primary-shadow: none;
    --btn-text-shadow: none;
    --modal-body-padding: 32px;
    --form-item-margin-bottom: 23px;
    --disabled-color: rgba(20,20,70,0.25);
    --disabled-bg: rgba(213,213,226,0.2);
    --menu-item-active-bg: rgba(243,243,251,1);
    --font-size-base: 14px;
    --border-radius-base: 4px;
    --padding-lg: 16px;
    --pink-color: rgba(255,100,146,1);
    --pink-color-rgb: 255,100,146;
    --purple-color: rgba(122,119,255,1);
    --purple-color-rgb: 122,119,255;
    --purple-03-color: rgba(175,173,255,1);
    --purple-03-color-rgb: 175,173,255;
    --purple-04-color: rgba(202,201,255,1);
    --purple-04-color-rgb: 202,201,255;
    --text-color: rgba(91,92,125,1);
    --text-color-rgb: 91,92,125;
    --dark-01-color: rgba(20,20,70,1);
    --dark-01-color-rgb: 20,20,70;
    --dark-02-color: rgba(67,67,107,1);
    --dark-02-color-rgb: 67,67,107;
    --dark-03-color: rgba(114,114,144,1);
    --dark-03-color-rgb: 114,114,144;
    --dark-04-color: rgba(161,161,181,1);
    --dark-04-color-rgb: 161,161,181;
    --dark-05-color: rgba(213,213,226,1);
    --dark-05-color-rgb: 213,213,226;
    --light-color: rgba(243,243,251,1);
    --light-color-rgb: 243,243,251;
    --green-color: rgba(65,181,111,1);
    --green-color-rgb: 65,181,111;
    --yellow-color: rgba(251,188,5,1);
    --yellow-color-rgb: 251,188,5;
    --gray-color: rgba(246,246,248,1);
    --gray-color-rgb: 246,246,248;

}

.register-container .pf-c-calendar-month{
  width: auto !important;
}

div.container-fluid.statistiche.register-container{
  width: fit-content !important;
  min-width: 100%;
} 


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


td.pvtAxisContainer.pvtVertList.pvtRows{
  border: none !important;
  background: #fff !important;
}

td.pvtAxisContainer.pvtVertList.pvtRows,
td.pvtAxisContainer.pvtHorizList.pvtCols, 
td.pvtAxisContainer.pvtUnused.pvtHorizList
> li > span{
  /*border: 1px solid var(--dark-03-color);
  color: var(--dark-03-color);*/
  border-bottom: none;
  border-left: none;
  border-right: none;
  /*box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2) !important;*/
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.38) !important;
}

.pvtUi{
  font-family: var(--font-family);
  width: 100% !important;
}

.pvtCheckContainer p.selected {
  background: none;
}

.pvtFilterBox{
  border: 1px solid #cccccc;
  border-radius: 3px;
  width: auto;
  padding: 15px;
  color: #2a3f5f;
}

.pvtFilterBox input[type="text"] {
	width: 90%;
	color: #2a3f5f;
	margin-bottom: 5px;
}

.pvtAxisContainer, .pvtVals {
  border: 1px solid #c9daf2;
  background: #ffffff;
  padding: 5px;
  min-width: 20px;
  min-height: 20px;
}

.pvt-table-style-button {
  --pvt-smart-button-color: rgba(255, 255, 255, 1) !important;
  --pvt-smart-button-text-color: rgb(255, 255, 255) !important;
  --pvt-smart-button-background: rgb(0, 102, 204) !important;
}

.pvtTriangle{
  @extend .pvt-table-style-button;
  color: var(--pvt-smart-button-color);
}

table.pvtTable{
  width: 100%;
  margin-top: 0px;
  /*font-size: 1.2em;*/
}

table.pvtTable thead tr th, table.pvtTable tbody tr th {
  /*font-size: 1.2em;*/
}

.pvtUI-filtered-attr{
  --background: #f0ab00;
  --color: black !important;
  --font-style: normal;
}

table.pvtUi tbody tr td.pvtAxisContainer.pvtHorizList.pvtCols li span.pvtAttr.pvtFilteredAttribute,
table.pvtUi tbody tr td.pvtAxisContainer.pvtVertList.pvtRows li span.pvtAttr.pvtFilteredAttribute
{
  @extend .pvtUI-filtered-attr;
  background: var(--background);
  color: var(--color);
  font-style: var(--font-style);
}

table.pvtUi tbody tr td.pvtAxisContainer.pvtHorizList.pvtCols li span.pvtAttr.pvtFilteredAttribute.pvtTriangle,
table.pvtUi tbody tr td.pvtAxisContainer.pvtVertList.pvtRows li span.pvtAttr.pvtFilteredAttribute.pvtTriangle
{
  color: black !important;
}



.pvt-smart-button {
  @extend .pvt-table-style-button;

  border: solid 1px !important;
  border-bottom-color: var(pvt-smart-button-color) !important;
  border-bottom-style: none !important;
  border-bottom-width: 0px !important;
  border-image-outset: 0 !important;
  border-image-repeat: stretch !important;
  border-image-slice: 100% !important;
  border-image-source: none !important;
  border-image-width: 1 !important;
  border-left-color: var(--pvt-smart-button-color) !important;
  border-left-style: none !important;
  border-left-width: 0px !important;
  border-right-color: var(--pvt-smart-button-color) !important;
  border-right-style: none !important;
  border-right-width: 0px !important;
  border-top-color: var(--pvt-smart-button-color) !important;
  border-top-style: none !important;
  border-top-width: 0px !important;
  box-sizing: border-box !important;
  color: var(--pvt-smart-button-text-color) !important;
  cursor: pointer !important;
  display: inline-block !important;
  font-family: "RedHatTextUpdated", "Overpass", overpass, helvetica, arial, sans-serif !important;
  font-size: 1em !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  margin-bottom: 6px !important;
  margin-left: 0px !important;
  margin-right: 6px !important;
  margin-top: 0px !important;
  padding-bottom: 6px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 6px !important;
  position: relative !important;
  text-align: center !important;
  user-select: none !important;
  white-space: nowrap !important;
  background: var(--pvt-smart-button-background);
}

.pvtButton {
	@extend .pvt-smart-button;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.pvtAxisContainer li span.pvtAttr {
  @extend .pvt-smart-button;
  -webkit-text-size-adjust: 100%;
  /* background: #0066cc; */
  color: black;
  /* border: 1px solid #c8d4e3; */
  padding: 2px 5px;
  white-space: nowrap;
  border-radius: 3px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  
}

.pvtAxisContainer.pvtUnused.pvtHorizList li:first-child:before {
  content: '';
  margin-left: "12px";
}

table.pvtTable{
  margin-left: 0px;
}

table.pvtVals{
  text-align: left;
}

.pvtTotalLabel{
  content: 'Totale' !important;
}

.pvtDropdownValue {
  padding: 5px 5px;
  font-size: 12px;
  text-align: left;
  min-height: 30px;
  border-radius: 3px;
  width: 90%;
}

.pvtAxisContainer li span.pvtAttr {
  -webkit-text-size-adjust: 100%;
  /*background: #f3f6fa;
  border: 1px solid #c8d4e3;*/
  padding: 2px 5px;
  white-space: nowrap;
  border-radius: 3px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

td.pvtAxisContainer.pvtUnused.pvtHorizList{

}

td.pvtAxisContainer.pvtVertList.pvtRows > li > span > span{
  content: "x" !important;
}

table.pvtTable thead tr th, table.pvtTable tbody tr th {
  /*background-color: #ffffff;
  border: 1px solid #c8d4e3;*/
  background-color: #fff;
  border: 1px solid #e9e9e9;
  /*font-size: 8pt;*/
  padding: 5px;
  /*font-family: "RedHatTextUpdated", "Overpass", overpass, helvetica, arial, sans-serif;*/
  /*width: 100%;*/
  box-shadow: 0 4px 20px 0 rgba(185, 185, 185, 0.2) !important;
}

.pvtAxisContainer, .pvtVals {
  /*border: 1px solid #a2b1c6;
  background: #ffffff;*/
  border: none;
  padding: 5px;
  min-width: 20px;
  min-height: 20px;
  text-align: left;
  border-top: none;
  border-left: none;
  border-right: none;
}

.pvtDropdown{
  width: 85%;
}

.pvtTable > thead:nth-child(1) > tr:nth-child(1) > th:nth-child(1){
  border: none;
}

table.pvtTable .pvtTotalLabel{
  text-align: center;
}

table.pvtTable tbody tr td{

}


