.pf-c-card .pf-c-card__body .form-group input{
    margin-top: 0 !important;
}
textarea{
    padding: 15px;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
}

.confButtons{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    
    .child1{
        margin-right: 5%;
    }
}