html, body, #root {
  height: 100%;
}

.pf-c-content {
  --pf-c-content--small--Color: red; /* changes all <small> color to red */
  --pf-c-content--blockquote--BorderLeftColor: purple; /* changes all <blockquote> left border color to purple */
  --pf-c-content--hr--BackgroundColor: lemonchiffon; /* changes a <hr> color to lemonchiffon */
}

.pf-c-page__header{
  --pf-c-page__header--BackgroundColor: #0066CC;
}

.pf-c-page__sidebar{
  --pf-c-page__sidebar--BackgroundColor: #0066CC;
}

.alert-m-b{
  margin-bottom: 20px;
}