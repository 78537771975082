@import './type.scss';

.container {
    @media (min-width:1400px) {
        max-width: 1635px !important;
    }
}

body{
    font-family: $Titillium !important;
}

.text-right{
    text-align:right;
}

.it-header-slim-wrapper .it-header-slim-wrapper-content .dropdown-menu .dropdown-menu-mobile .dropdown-menu.show .dropdown-menu-mobile.show {
    top: 0 !important;
    left: 18px !important;
    width: 100%;
    position: initial;
    background-color: #0073E6;
}
.it-header-slim-wrapper{
    height: 80px !important;
    display: flex;
    align-items: center;

    .btn-icon{
        height: 60px;
        background: #003366;
        .rounded-icon{
            width: 49px;
            height: 49px;
            border-radius: 50%;
        }
    }
}
.it-header-center-wrapper{
    height: 170px;
    .it-brand-text{
        display: flex;
        padding-bottom: 15px;
        h1{
            font-size: 40px !important;
            color: #fff;
        }
        h3{
            font-size: 19px;
        }
        .ml{
            margin-left: 30px;
        }
        img{
            height: 100%;
            position: relative;
            top: 30px;
        }
    }
    .it-socials{
        a, a:focus, a:hover, a:active, a.active{
            box-shadow: none !important;
            outline: 0 !important;
            border-color: none;
        }
    }
    .site-link, .site-link:hover, .site-link:focus{
        color: #fff;
        font-weight: bold;
    } 
}
.it-header-navbar-wrapper{
    .navbar{
        border-top: 1px solid #616161;
        .nav-link{
            span{
                button{
                    color: #fff;
                    font-weight: 400;
                    padding: 0;
                }
            }
        }
    }
}

.register-container{

    h1{
        margin-bottom: 30px;
        font-weight: bold;
    }
    h2{
        font-size: 24px;
        padding: 40px 0 20px;
    }
    .card{
        padding: 20px 20px 0;
    }
    .card::after{
        margin: 0;
    }
    .card-title{
        font-size: 24px;
    }
    .card-body{
        padding:24px 24px 0 !important;
        height: auto !important;
    }
    .select-group label{
        top: 30px !important;
    }
    .single.select-group label {
        top: 58px !important;
    }
    .multi.select-group{
        div {
            height: auto;
        }
        label {
            position: relative;
            top: 20px !important;
        }

    }
    .form-group label{
        top: 57px;
        font-size: .777rem;
    }
    .pf-c-form-control{
        width: 260px;
        border-color: #0073E6;
        color: #0073E6;
    }
    #undefined-browse-button{
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        z-index: 100;
    }
    button[aria-disabled="false"]{
        z-index: 101;
    }
    .nav-link.active{
        span{
            background: #0073E6;
            border-color: #0073E6;
            color: #fff;
        }
    }
    .nav-link{
        justify-content: flex-start !important;
        span{
            border: 1px solid #979797;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: 28px;
            margin-right: 5px;
        }
    }
    input[type=text]:focus{
        box-shadow: none !important;
    }
    .css-1hb7zxy-IndicatorsContainer{
        position: absolute;
        right: 0;
        top: -10px;
    }
    .css-zsohrp-placeholder{
        top: 60%;
    }
  

}
.register-container-zero-padding{

    h1{
        margin-bottom: 30px;
        font-weight: bold;
    }
    h2{
        font-size: 24px;
        padding: 0;
    }
    .card{
        padding: 0;
    }
    .card::after{
        margin: 0;
    }
    .card-title{
        font-size: 24px;
    }
    .card-body{
        padding:0;
        height: auto !important;
    }
    .select-group label{
        top: 30px !important;
    }
    .single.select-group label {
        top: 58px !important;
    }
    .multi.select-group{
        div {
            height: auto;
        }
        label {
            position: relative;
            top: 20px !important;
        }

    }
    .form-group label{
        top: 57px;
        font-size: .777rem;
    }
    .pf-c-form-control{
        width: 260px;
        border-color: #0073E6;
        color: #0073E6;
    }
    #undefined-browse-button{
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        z-index: 100;
    }
    button[aria-disabled="false"]{
        z-index: 101;
    }
    .nav-link.active{
        span{
            background: #0073E6;
            border-color: #0073E6;
            color: #fff;
        }
    }
    .nav-link{
        justify-content: flex-start !important;
        span{
            border: 1px solid #979797;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: 28px;
            margin-right: 5px;
        }
    }
    input[type=text]:focus{
        box-shadow: none !important;
    }
    .css-1hb7zxy-IndicatorsContainer{
        position: absolute;
        right: 0;
        top: -10px;
    }
    .css-zsohrp-placeholder{
        top: 60%;
    }
  

}
.stepBtns{
    button{
        margin: 40px 40px 40px 0;
    }
}

.vertical-tab{
    .nav-link.active{
        span{
            background: #0073E6;
            border-color: #0073E6;
            color: #fff;
        }
    }
    .nav-link{
        justify-content: flex-start !important;
        span{
            border: 1px solid #979797;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: 28px;
            margin-right: 5px;
        }
    }
}


.bootstrap-select-wrapper{
    div{
        margin-top: 0 !important;
    }
    label{
        z-index: 0 !important;
    }
}
.form-group{
    label{
        z-index: 0 !important;
    }
}
.form-row{
    label{
        top: 0 !important;
        font-size: 1rem !important;
    }
    .input-upload{
        position: relative;
        top: 50px;
        p{
            font-size: 18px;
            padding-top: 5px;
            width: 350px;
        }
        input{
            opacity: 0;
            position: absolute;
        }
        button{
            border: 0;
        }
    }
    .clicked{
        .bordered{
            border-bottom: 4px solid #0073E6;
            display: inline-flex;
            height: 45px;
            margin-bottom: 10px;
            p{
                color: #0073E6;
                font-weight: bold;
                padding-bottom: 10px;
            }
        }
    }
}
.table>:not(:first-child){
    border-top: 0 !important;
}
table {
    th{
        font-size: 22px;
    }
    td{
        border: 0;
    }
}
.hideOnPolicy{
    display: block;
}
table.form-table{
    th{
        font-size:14px;
    }
    .form-group{
        margin-bottom:0px;
    }
    td{
        border-bottom:1px solid #b1b1b3;
        vertical-align:baseline;
    }
}

.form-section{
    h3{
        padding-bottom:30px;
    }
}


.wrap-loader{
    display:flex;
    align-items:center;
    justify-content:center;
    padding:20px;
}



//TODO remove
#webpack-dev-server-client-overlay{
    display:none;
}

.side-bar-mobile {
    // margin: -10px;
    // margin-left: 24px;
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    margin-left: 5%;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    .resp-header {
        background-color: red !important;
        width: 100% !important;
        max-width: 100% !important;
    }
    .resp-wrap-it {
        overflow-wrap: break-word !important;
    }
    .fw-bold {
        font-size: small;
    }
    .cookie-bar {
        display: inherit;
        position: fixed;
        top: 25%;
        margin-bottom: 90%;
    }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
    .resp-header {
        background-color: yellow !important;
        width: 100% !important;
        max-width: 100% !important;
    }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px){
    .resp-header {
        background-color: blue !important;
        width: 100% !important;
        max-width: 100% !important;
    }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px){
    .resp-header {
        background-color: green !important;
        width: 100% !important;
        max-width: 100% !important;
    }
    .container-padding {
        padding: 20px;
    }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
    .resp-header {
        background-color: white !important;
        width: 100% !important;
        max-width: 100% !important;
    }
    .container-padding {
        padding: 20px;
    }
    
}