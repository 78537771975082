@import '../../frontend/style/type.scss';

body {
    font-family: $Titillium !important;
}

.pf-c-nav__link.pf-m-current,
.pf-c-nav__link.pf-m-current:hover,
.pf-c-nav__link.pf-m-current.active,
.pf-c-nav__item.pf-m-current:not(.pf-m-expanded) .pf-c-nav__link,
.pf-c-nav__link:hover,
.pf-c-nav__link:active,
.pf-c-nav__link:focus {
    background-color: rgb(0 39 78 / 60%) !important;
    font-weight: bold;
    outline: 0;
    box-shadow: none;
}

:focus:not(.focus--mouse),
.form-check [type=checkbox]:focus+label,
.form-check [type=radio]:focus+label,
.toggles label input[type=checkbox]:focus+.lever {
    border-color: none !important;
    box-shadow: none !important;
}

.boHeader {
    .pf-c-button.pf-m-primary {
        margin-right: 20px;
        background-color: #ffffff !important;
        color: #0055ab;
    }
}

.label-style {
    justify-content: space-between;
}

.d-block {
    display: block;
}

h1 {
    padding: 20px 0 !important;
    font-family: $Titillium !important;
    font-weight: bold !important;
    letter-spacing: normal !important;
}

.pf-c-wizard__footer {
    .pf-c-button.pf-m-primary {
        background-color: #0053a7 !important;
        font-weight: bold;
        color: #ffffff !important;
    }
}

.stepTitle {
    font-size: 32px;
    font-weight: bold;
    padding: 20px 0
}

.pf-c-card {
    margin: 20px 0 !important;
    border: 1px solid #d7d7d7;
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 20%) !important;

    .pf-c-card__title {
        border-bottom: 1px solid #d7d7d7;
        font-size: 20px;
        color: rgb(0, 77, 155) !important;
    }

    .pf-c-card__body {
        padding: 30px 24px 0;

        .data-category {
            font-weight: bold;
        }

        .form-group {
            margin-bottom: 2rem;

            label {
                top: -5px;
            }

            input {
                margin-top: 15px;
            }
        }

        .avatar.size-xl p {
            font-weight: bold;
        }
    }
}

.card:after {
    content: none;
    margin-top: 0;
}

.pf-c-table thead, .pf-c-table .pf-m-truncate .big-table{
    --pf-c-table--cell--Overflow: unset !important;
    --pf-c-table--cell--TextOverflow: unset !important;
    --pf-c-table--cell--WhiteSpace: unset !important;
}

.pf-c-table__text {
    min-width: 1em !important;
}

.filterSection{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: baseline;
    gap: 1%;
}

.filterElem{
    padding-bottom: 1%;
}
