body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* REFACTOR RESPONSIVE */

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .resp-header {
      background-color: red !important;
      width: 100% !important;
      max-width: 100% !important;
  }
  .resp-wrap-it {
    overflow-wrap: break-word !important;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .resp-header {
      background-color: yellow !important;
      width: 100% !important;
      max-width: 100% !important;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px){
  .resp-header {
      background-color: blue !important;
      width: 100% !important;
      max-width: 100% !important;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px){
  .resp-header {
      background-color: green !important;
      width: 100% !important;
      max-width: 100% !important;
  }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  .resp-header {
      background-color: white !important;
      width: 100% !important;
      max-width: 100% !important;
  }
}
a.menu-item-link:after {
  background: transparent !important;
}

.dropdown-menu.show {
  width: 240px;
  position: initial;
}

